import React from "react";
import InnerHead from "../components/InnerHead";
import Header from "../components/Header";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import TrackDetails from "../components/TrackDetails";

const Cfp = () => {
  return (
    <div>
      <Header />
      <InnerHead />
      <div
        className="section-title text-center position-relative py-3 my-2 mx-auto"
        style={{ maxWidth: "600px" }}
      >
        <h5 className="fw-bold text-primary text-uppercase">Call for Papers</h5>
        <h2 className="mb-0">ICACCTech 2025</h2>
      </div>
      <div className="container my-5">
        <div className="card shadow-lg">
          <div className="card-body">
            <p className="card-text text-justify">
              <b>Full Papers: </b>Submissions are invited for International
              Conference on Advanced Computing and Communication Technologies
              India. This is an exceptional platform for researchers, academics,
              students, innovators, scientists and industry practitioners to
              discuss current research which is shaping a new world society.
              This conference will be a good platform to share research,
              innovations, and best-practices where constructive feedback and
              engagement with domain experts is expected. Many topics of the
              advanced computing technologies as a response to everyday
              challenges faced in both developing and developed world contexts
              will be discussed. We encourage submissions of cross-disciplinary
              nature cutting across many disciplines/subject-areas. We encourage
              any submissions that present novel ideas, works-in-progress or any
              concepts that you think can benefit from inputs from experts.
              Students and/or emerging researchers are encouraged to
              participate. <br />
              The following are the different tracks in this conference:
            </p>
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li>
                    <h5>◆ Artificial Intelligence and Machine Learning</h5>
                    <p className="text-justify">
                      Advances in AI and ML algorithms, reinforcement learning,
                      neural networks, deep learning models, and applications of
                      AI/ML in various domains such as robotics, natural
                      language processing, and computer vision.
                    </p>
                  </li>
                  <li>
                    <h5>
                      ◆ Digital Security, Information Privacy and Web Technology
                    </h5>
                    <p className="text-justify">
                      Enhancements in web security frameworks, data privacy
                      policies, encryption techniques, identity management, and
                      safeguarding digital communications.
                    </p>
                  </li>
                  <li>
                    <h5>◆ Internet of Everything and Sensor Networks</h5>
                    <p className="text-justify">
                      Integration of IoT systems with sensor networks, security
                      issues, smart environments, and the communication
                      protocols enabling sensor-based ecosystems.
                    </p>
                  </li>
                  <li>
                    <h5>
                      ◆ Healthcare Analytics, CoVID-19 Technologies and Data
                      Analysis
                    </h5>
                    <p className="text-justify">
                      Use of data analytics in healthcare, predictive models,
                      CoVID-19 related innovations, healthcare data privacy, and
                      the impact of AI in healthcare decision-making processes.
                    </p>
                  </li>
                  <li>
                    <h5>◆ Software Engineering and Cloud Computing</h5>
                    <p className="text-justify">
                      Innovations in software development methodologies, agile
                      and DevOps practices, advancements in cloud platforms,
                      cloud-native architectures, and service-oriented
                      computing.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                    <h5>◆ Digital Image and Video Processing</h5>
                    <p className="text-justify">
                      Image recognition, video analytics, computer vision
                      advancements, pattern recognition, and processing
                      algorithms applied in various fields.
                    </p>
                  </li>
                  <li>
                    <h5>◆ Circuits and Systems and Quantum Computing</h5>
                    <p className="text-justify">
                      Development of advanced circuit designs, VLSI, quantum
                      computing principles, algorithms, and their application in
                      cryptography and communication.
                    </p>
                  </li>
                  <li>
                    <h5>◆ Communication Technologies</h5>
                    <p className="text-justify">
                      Recent trends in 5G/6G, satellite communications, wireless
                      and optical communication, and advancements in network
                      protocols.
                    </p>
                  </li>
                  <li>
                    <h5>◆ E-Commerce and E-Governance</h5>
                    <p className="text-justify">
                      Emerging trends in digital transactions, blockchain
                      applications in governance, secure payment systems, and
                      public policy implications of digital governance.
                    </p>
                  </li>
                </ul>
              </div>
              <p className="text-justify">
                This is a peer-reviewed conference. Authors are cordially
                invited to submit papers through on line paper submission
                process before deadline.
              </p>
            </div>
            {/* <h5 className="card-text my-4 text-justify">
              <b>
                Conference proceedings will be published with an ISBN number in
                electronic form by CPS and submitted to Xplore and CSDL. <br />
                All accepted papers will be submitted by CPS to major indexing
                companies (Scopus, WoS, Ei Compendex, and others).
              </b>
            </h5> */}
            <h3 className="text-center mt-4 pt-4">
              <b>Instructions For Authors</b>
            </h3>
            <ul>
              <li className="text-justify">
                1. Original papers based on theoretical or experimental works
                related to the conference themes are invited.Original papers
                based on theoretical or experimental works related to the above
                mentioned sub themes are solicited for presentation in the
                conference. The paper should begin with title, short abstract
                and a list of keywords. Simultaneous submissions (papers already
                submitted to other conferences/journals) are not allowed.
              </li>
              {/* <li className="text-justify">
                2. All papers must follow (given in point 5) formatting
                instructions.
              </li> */}
              <li className="text-justify">
                2. Please do not submit plagiarized papers..
              </li>
              <li className="text-justify">
                3. Authors are requested to submit paper through given link:{" "}
                <a href="https://cmt3.research.microsoft.com/ACCT2024">
                  Microsoft CMT Paper Submission Link.
                </a>
              </li>
            </ul>
            <p className="text-justify">
              Authors are requested to submit their papers before the deadline
              using the provided submission link.
            </p>
            <h3 className="text-center mt-4 pt-4">
              <b>Copy Right Form:</b>
            </h3>
            <ul>
              <li className="text-justify">
                1. In order to publish paper in the conference proceeding, the
                authors are required to submit a signed e-copyright form for
                each accepted paper during final camera ready paper submission.
              </li>
            </ul>
            <p className="mt-4">
              <strong>Useful Links:</strong>
            </p>
            <ul>
              <li>
                <a href="https://cmt3.research.microsoft.com/ACCT2024">
                  Microsoft CMT Paper Submission Link
                </a>
              </li>
              <li>
                <a href="https://docs.google.com/document/d/1KxR5Qh4_Z9ofmNAqQvEIEhAKGM5IodMj/edit">
                  IEEE Paper Format
                </a>
              </li>
              {/* <li>
                <a href="#">Copy Right Form</a>
              </li> */}
            </ul>
            <p className="card-text">
              The Microsoft CMT service was used for managing the peer-reviewing
              process for this conference. This service was provided for free by
              Microsoft and they bore all expenses, including costs for Azure
              cloud services as well as for software development and support.
            </p>
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Cfp;
